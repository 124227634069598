#product-images-manager {
  height: 100%;
}

#product-images-manager .left-panel {
  height: 100%;
  //width: 30%;
  display: flex;
  flex-direction: column;
}

#product-images-manager .left-panel .image-frame {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

#product-images-manager .left-panel .image-frame .large-image {
  height: auto;
  width: 100%;
  position: relative;
}

#product-images-manager .left-panel .image-frame .large-image img {
  width: 100%;
}

#product-images-manager .left-panel .zoom-frame {
  position: absolute;
  background-color: hsl(var(--primary));
  opacity: 0.3;
  pointer-events: none;
}


#product-images-manager .right-panel {
  width: 100%;
  height: 100%;
  padding: 0 0rem;
}

#product-images-manager .right-panel .image-magnifier-wrapper {
  width: 100%;
  height: 100%;
}
