.image-magnifier {
  width: 100%;
  height: 100%;
  position: relative;
}

.magnifier {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  & img {
    position: absolute;
    /* width: 100%;
    max-width: 100%; */
    transform: scale(1, 1);
  }
}
