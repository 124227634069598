@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');

:root {
  --up: 18;
  --card-margin: 4px;
  --card-shadow: rgba(46, 49, 52, .4);
  --color-white: #fff;
  --color-lightgrey: #ccc;
  --color-ui: #999;
  --color-ui-hover: #666;
  --cardWidth: calc(96vw/var(--up));
  --carouselWidth: calc(var(--cardWidth) * var(--num));
  /* --carouselLeftMargin: calc(2vw - var(--cardWidth)); */
  --carouselLeftMargin: 0;

  @media screen and (max-width: 900px) {
    /*basically mobile*/
    --up: 1;
  }
}

#carousel {
  width: 100%;
  /* padding: 0 calc(20px + var(--card-margin)); */
  position: initial;
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
}

.carouselwrapper {
  overflow: hidden;
  background: var(--color-lightgrey);
  background: hsl(var(--primary-foreground));
  background: white;
  position: relative;
  width: 100%;
}

.carousel {
  width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  &.prev {
    animation-duration: .6s;
    animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    animation-name: prev;
  }

  &.next {
    animation-duration: .6s;
    animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    animation-name: next;
  }

  li.card {
    padding: calc(var(--card-margin) / 2);
    margin: calc(var(--card-margin) / 2);
    text-align: center;
    width: calc(var(--cardWidth) - var(--card-margin) * 2);
    box-sizing: border-box;
    cursor: pointer;
    /* background-color: hsl(var(--secondary)); */
    display: flex;
    justify-content: center;

    &.selected {
      /* background-color: var(--card-shadow);
      background-color: hsl(var(--primary)); */
      border: 2px solid hsl(var(--primary));
    }
  }

  img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

#carousel button {
  position: relative;
  cursor: pointer;
  background: var(--color-ui);
  background: hsl(var(--primary));
  background: white;
  width: 20px;
  height: calc(100% - var(--card-margin));
  /* border-radius: 50%; */
  color: var(--color-white);
  color: hsl(var(--secondary));
  //box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, .25);
  border: 0;
  transition: all .2s cubic-bezier(0.39, 0.575, 0.565, 1);

  &.prev {
    position: absolute;
    //top: calc(var(--card-margin)/2);
    top: 0;
    left: 0.725rem;
  }

  &.next {
    position: absolute;
    //top: calc(var(--card-margin)/2);
    top: 0;
    right: 0.725rem;
  }

  &:hover {
    background: var(--color-ui-hover);
    background: var(--secondary-foreground);
    background: var(--secondary-foreground);
    color: var(--secondary);
  }

  &:focus {
    outline: none;
    border: 1px solid rgba(255, 255, 255, 1);
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-size: 2.5rem;
  }
}


.card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  background: var(--color-white);

  .material-icons {
    font-size: 4.8rem;
  }

  p {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

@keyframes next {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes prev {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}
