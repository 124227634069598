.active-row {
  @apply bg-accent brightness-[105%] text-accent-foreground hover:bg-accent hover:brightness-[90%] dark:bg-white dark:text-black hover:text-white
  /* stiky */
  !important;
}

.active-row svg {
  @apply fill-primary stroke-secondary;
}

.active-row svg.active {
  @apply fill-secondary stroke-primary;
}

.active-row svg.active-2 {
  @apply fill-secondary stroke-secondary;
}

.active-row [role="checkbox"] {
  @apply border-secondary;
}

.might-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.might-overflow:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#data-table-sheet-content>button {
  display: none;
}
